<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.332 8.93366V10.9337C11.332 13.6003 10.2654 14.667 7.5987 14.667H5.06536C2.3987 14.667 1.33203 13.6003 1.33203 10.9337V8.40033C1.33203 5.73366 2.3987 4.66699 5.06536 4.66699H7.06537"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3331 8.93366H9.19974C7.59974 8.93366 7.06641 8.40033 7.06641 6.80033V4.66699L11.3331 8.93366Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.73438 1.33301H10.401"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66797 3.33301C4.66797 2.22634 5.5613 1.33301 6.66797 1.33301H8.41464"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6663 5.33301V9.45967C14.6663 10.493 13.8263 11.333 12.793 11.333"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.668 5.33301H12.668C11.168 5.33301 10.668 4.83301 10.668 3.33301V1.33301L14.668 5.33301Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>
